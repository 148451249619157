section.team-members {
  padding: rem-calc(100 0);
  .description {
    padding-bottom: rem-calc(50);
  }

  .tabs {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background: none;
    border: none;
    padding: rem-calc(15 0);
    .tabs-title {
      margin: rem-calc(0 10);
      & > a:focus, .tabs-title > a[aria-selected='true'] {
        background: none;
        padding: 0;
      }
        & > a {
        display: block;
        padding: 0;
        font-size: 0.75rem;
        line-height: 1;
        color: #1779ba;
      }
    }
  }

  .tabs-content {
    background: $blue-light;
    border: none;
    .tabs-panel {
      font-family: $secondary-font;
      color: $white;
      h1,h2,h3,h4,h5,h6,li {
        font-family: $secondary-font;
      }
    }
  }

  // Overlay
  .overlay {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      margin-top: auto;
      margin-bottom: auto;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: $overlay;
      color: #FFF;
      transition: .25s ease-in-out;
    }
    &:hover::before {
      cursor: pointer;
      background-color: rgba(0,0,0,.1);
    }
  }

  .member-info {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: rem-calc(25);
    z-index: 10;
    h6 {
      font-family: $secondary-font;
      color: $white;
      margin: 0;
      text-align: center;
    }
  }
}

// Mobile
.team-modal-btn {
  img {
    position: relative;
    padding-bottom: rem-calc(5);
  }
}

.team-modal {
  background: $blue-light;
  color: $white;
}
