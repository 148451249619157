section.contact {
  background: $blue-light;
  padding: rem-calc(75 0);

  .contact-header {
    h2 {
      font-size: rem-calc(44);
      padding-bottom: rem-calc(40);
      color: $white;
      text-transform: uppercase;
      letter-spacing: rem-calc(2);
    }
  }

  .contact-form {
    position: relative;
    padding-bottom: rem-calc(40);

    .infusion-form {
      input,textarea {
        border-radius: 7px;
      }
      input, textarea {
        margin: 0;
        margin-bottom: rem-calc(20);
        &::-webkit-input-placeholder {
           color: $black;
         }
         &:-moz-placeholder {
           color: $black;
           opacity: 1;
         }
         &::-moz-placeholder {
           color: $black;
           opacity: 1;
         }
         &:-ms-input-placeholder {
           color: $black;
         }
      }
      textarea {
        height: calc(100% - 20px);
      }
    }
  }
}
