// Extends Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group header
////

/// Media query to show full menu
/// @type String
$topbar-breakpoint: navBreak;

/// Height of topbar at $topbar-breakpoint and up.
/// @type Number
$topbar-height: 115px;

/// Reset the default $topbar-padding value.
/// @type Number
$topbar-padding: 0 rem-calc((map-get($grid-column-gutter, medium) / 2));

/// Height of title bar below $topbar-breakpoint.
/// @type Number
$titlebar-height: 115px;

/// Reset the default $titlebar-padding value.
/// @type Number
$titlebar-padding: 0 rem-calc((map-get($grid-column-gutter, small) / 2));

/// Add nesting to submenu's -- Best used with accordion menu.
/// @type Boolean
$titlebar-nested: false;

/// Adds styles for a top bar container.
.top-bar {
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: 999;

  .top-bar-row {
    @include flex;
    width: 100%;
    max-width: $global-width;
    justify-content: space-between;
    align-items: center;
  }

  // Styles for $topbar-breakpoint and up should go here
  @include breakpoint($topbar-breakpoint) {
    height: $topbar-height;

    .top-bar-row {
      max-width: 100vw;
      width: 100%;
      justify-content: space-around;
      .menu-items,
      .menu-logo {
        > li {
          > a {
            padding: $topbar-padding;
            line-height: $topbar-height;
            color: $black;
            text-transform: uppercase;

            img {
              max-height: $topbar-height;
            }
          }
        }
      }
      .vertical {
        .menu-item {
          height: rem-calc(50);
          transition: .25s ease-in-out;
          &:hover{
            background: $blue-light;
          }
          &:hover > a {
            color: $white;
            opacity: .5;
          }
          &:last-child {
            a {
              border: none;
            }
          }
          a {
            border-right: 1px $gold solid;
            line-height: rem-calc(15) !important;
            font: {
              weight: 700;
            }
          }
        }
      }
    }
  }
}

/// Adds styles for a title bar container.
.title-bar {
  padding: 0;
  height: $titlebar-height;
  display: flex;
  align-items: center;

  .title-bar-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 50%;
    a {
      padding: 0;

      img {
        max-height: $titlebar-height;
      }
    }
  }

  .title-bar-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 50%;
    .menu {
      width: 100px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-end;
      li {
        // flex-grow: 1;
        button {
          outline: none;
        }
      }
    }
  }
}

.nav-active {
  background: $blue-light;
}

/// Adds nesting to submenu's if $title-bar nestes is true.
@if $titlebar-nested == true {
  @include breakpoint($topbar-breakpoint) {
    .top-bar .submenu {
      padding-left: 1rem;
    }
  }
}

/// Fixes the split-second flicker of the title bar on desktop
/// You may need to adjust the breakpoints for your project
.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

// Mobile Dropdown Menu
@include breakpoint(1215px down) {
  .top-bar {
    position: absolute;
    right: 0;
    background: transparent;
  }

  .top-bar-right {
    // width: 100% !important;
    width: 100vw !important;
    .is-drilldown {
      z-index: 99999;
      max-width: 300px !important;
      width: 300px;
      float: right;
      .menu-item {
        background: $blue-light;
        padding: rem-calc(20 0 20 20);
        a {
          color: $white;
          text-transform: uppercase;
          // line-height: rem-calc(40);
          font: {
            family: $secondary-font;
            weight: 700;
            size: rem-calc(21);
          }
        }
      }
    }
  }
}

// Desktop Dropdown Menu
.dropdown.menu.navBreak-horizontal > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  left: 0;
  border: none;
  a {
    color: $white;
    border: none;
    font: {
      weight: 400;
      size: rem-calc(16);
    }
  }
}

//dropdown arrow icon
.dropdown.menu.navBreak-horizontal > li.is-dropdown-submenu-parent > a::after {
  display: none;
  left: auto;
}

.dropdown.menu.navBreak-horizontal > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  left: auto;
  right: 0;
  border: none;
}

.nav-cta {
  position: relative;
  .call {
    position: absolute;
    right: 320px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .join {
    position: absolute;
    right: 50px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .call:hover,.join:hover {
    padding: rem-calc(30 65);
  }
}

.menu .active > a {
  background: none;
}


.dropdown.menu.navBreak-horizontal > li.opens-left > .is-dropdown-submenu a {
  height: 100%;
  display: flex;
  align-items: center;
}

.dropdown.menu.navBreak-horizontal > li.opens-left > .is-dropdown-submenu {
   top: 50px;
}
