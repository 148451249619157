.hero {
  position: relative;
	z-index: 1;

  .hero-heading {
    background-color: $blue-dark;
    padding: rem-calc(50) 0;
    h1 {
      text-transform: uppercase;
      color: $white;
      margin: 0;
      letter-spacing: rem-calc(2);
    }
  }

  .hero-subheading {
    background-color: $blue-dark;
    color: $white;
    padding: rem-calc(50) 0;
    h3 {
      text-transform: uppercase;
      letter-spacing: rem-calc(2);
      font-size: 2rem;
    }

    p {
      margin: 0;
    }
    &.light-blue {
      background-color: $blue-light;
    }
  }
  @include breakpoint(navBreak) {
    padding-top: rem-calc(115);
  }

  //Hero Video\
  .flex-video {
    position: relative;
    margin: 0;
    z-index: 2;
    .heading {
      width: 100%;
      color: $white;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      h1,h3 {
        text-transform: uppercase;
        letter-spacing: rem-calc(2);
      }
      h3 {
        padding-bottom: rem-calc(50);
        @include breakpoint(small only) {
          padding-bottom: 0;
          font-size: rem-calc(15);
        }
      }
      p {
        @include breakpoint(small only) {
          font-size: rem-calc(10);
        }
      }
    }
  }

  // Hero Image
  .hero-image {
    display: flex;
    align-items: center;
    height: 600px;
    background: {
      size: cover;
      position: center;
    }

    // Hero Info Block
    .content {
      // padding: rem-calc(150 0);
      position: relative;
      z-index: 10;
      .hero-cta {
        padding-bottom: rem-calc(50);
        a {
          padding: rem-calc(20 0);
          width: rem-calc(200);
          margin: rem-calc(10 10);
        }
      }
      // Hero Nav Links
      .hero-menu {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: rem-calc(25);
        list-style-type: none;
        .menu-item {
          height: rem-calc(30);
          transition: .25s ease-in-out;
          &:hover{
            opacity: .7;
          }
          &:last-child {
            a {
              border: none;
            }
          }
          a {
            padding-right: 10px;
            padding-left: 10px;
            border-right: 1px $gold solid;
            line-height: rem-calc(15) !important;
            letter-spacing: rem-calc(2);
            text-transform: uppercase;
            color: $gold;
            font: {
              weight: 700;
            }
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  // Overlay
  // .overlay,.video-overlay {
  //   position: relative;
  //   &:before {
  //     content: '';
  //     position: absolute;
  //     bottom: 0;
  //     top: 0;
  //     margin-top: auto;
  //     margin-bottom: auto;
  //     width: 100%;
  //     height: 75%;
  //     z-index: 1;
  //     background-color: $overlay;
  //     color: #FFF;
  //     @include breakpoint(navBreak) {
  //       content: '';
  //       position: absolute;
  //       top: 0;
  //       margin-left: auto;
  //       margin-right: auto;
  //       left: 0;
  //       right: 0;
  //       width: 65%;
  //       height: 100%;
  //       z-index: 1;
  //       background-color: $overlay;
  //       color: #FFF;
  //     }
  //   }
  // }
  // .video-overlay {
  //   &:before {
  //     height: 100%;
  //     @include breakpoint(navBreak) {
  //       width: 100%;
  //     }
  //   }
  // }
}
