section.services {
  background: $blue-light;
  color: $white;
  h2 {
    text-transform: uppercase;
    padding-bottom: rem-calc(20);
  }

  .services-image {
    max-height: rem-calc(500);
    @include breakpoint(large down) {
      padding: rem-calc(300 0);
    }
  }

  .services-info-container {
    padding: rem-calc(100 0);
    .content {
      padding-bottom: rem-calc(20);
      text-align: left;
    }
  }
}

section.services-left {
  color: $black;
}
