section.facility-blocks {

  .tabs {
    display: flex;
    justify-content: space-around;
    background: none;
    border: none;
    padding: rem-calc(50 0 50 0);
    @include breakpoint(medium down) {
      flex-direction: column;
      align-items: center;
      padding: rem-calc(10 0 0 0);
    }
    .tabs-title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: rem-calc(325);
      height: rem-calc(325);
      @include breakpoint(facility down) {
        width: rem-calc(200);
        height: rem-calc(200);
      }
      @include breakpoint(medium down) {
        width: rem-calc(300);
        height: rem-calc(300);
        margin: rem-calc(10 0 5 0);
      }
      h6 {
        color: $white;
        text-align: center;
        text-transform: uppercase;
        font-size: rem-calc(33);
        @include breakpoint(large down) {
          font-size: rem-calc(25);
        }
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 2;
      }
    }
    // Overlay
    .overlay {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        margin-top: auto;
        margin-bottom: auto;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: $blue-overlay;
        color: #FFF;
      }
    }
  }

  .tabs-content {
    background: $blue-light;
    border: none;
    color: $white;
    font-family: $secondary-font;
    .tabs-panel {
      padding: rem-calc(50 0);
      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        a {
          width: 50%;
          align-self: center;
        }
      }
    }
  }

  .facility-img {
    padding: rem-calc(150);
  }

  .tabs-mobile {
    .tabs-title:hover {
      cursor: pointer;
    }

    .facility-info-mobile {
      h6 {
        text-align: center;
        position: relative;
        z-index: 3;
      }
    }
  }
}
.button-container-mobile {
  display: flex;
  justify-content: center;
}
