.happening {
  padding-top: rem-calc(50);
  .project-blocks {
    background: $blue-light;
    margin-bottom: rem-calc(20);
    .project-blocks-background {
      height: rem-calc(220);
      margin: rem-calc(40) rem-calc(20);
      background: {
        size: cover;
        position: center;
        repeat: no-repeat;
      }
    }

    .project-blocks-info {
      padding: rem-calc(20);
      color: $white;
      h3,h4,a {
        color: $white;
      }
      h3 {
        padding-bottom: rem-calc(10);
        margin: 0;
        font: {
          size: rem-calc(32);
        }
      }
      h4 {
        padding-bottom: rem-calc(10);
        font: {
          size: rem-calc(24);
          family: $secondary-font;
        }
      }
      a {
      }
    }
  }
}


.single-happening {
  color: $white;

  .single-happening-image {
    background: {
      size: cover;
      position: center;
    }
    height: rem-calc(220);
    width: 100%;
    margin-bottom: 20px;
  }
  .row {
    padding-top: rem-calc(50);
    padding-bottom: rem-calc(50);
    background: $blue-light;
  }

  .video {
    padding: rem-calc(20 0);
  }

  .filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: $white;

    .prev-post,.next-post {
      a {
        color: $white;
        transition: .25s ease-in-out;
        &:hover {
          opacity: .7;
        }
      }
    }
    .prev-post:before {
      content: '<< ';
    }
    .next-post:after {
      content: ' >>';
    }
  }

  .directory {
    margin: 0;
    color: $white;
    transition: .25s ease-in-out;
    &:hover {
      opacity: .7;
    }
  }
}
