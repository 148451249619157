body.page-template-template-join-the-team {
  margin-bottom: -20px;

  .reveal[id^="benefit-modal-"] {
    button.close-button {
      font-size: 4em;
      color: $blue-light;
    }

    section {
      padding: 3rem;

      h3 {
        @extend h1;
        color: $blue-dark;
        text-transform: uppercase;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    }

    @include breakpoint(large) {
      section {
        padding: 4rem 8rem;
      }
    }
  }

  main.main {
    @include breakpoint(navBreak) {
      padding-top: rem-calc(115);
    }

    .top-margin {
      margin-top: 4rem;
    }

    header.header-banner {
      .header-banner--banner {
        background: $blue-dark;
        padding: 3rem 0;

        h1 {
          display: block;
          text-transform: uppercase;
          color: $white;
        }
      }

      .header-banner--image {
        background: {
          size: cover;
          position: center center;
        }
        height: 300px;

        @include breakpoint(medium) {
          height: 542px;
        }
      }

      .header-banner--content {
        background: $blue-light;
        color: $white;
        padding: 3rem 0;

        h2 {
          text-transform: uppercase;
          @extend h1;
        }

        .content {
          margin: 2rem 0 3rem 0;
        }
      }
    }

    section.benefits {
      padding: 3rem 0;

      h3 {
        text-transform: uppercase;
        @extend h1;
        margin-bottom: 3rem;
      }

      .benefits {
        &--item {
          margin-bottom: 2rem;
          cursor: pointer;

          .background {
            height: 200px;
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 1rem;
            background: {
              size: cover;
              position: center center;
            }

            @include breakpoint(medium) {
              height: 286px;
            }

            &::after {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: rgba($blue-dark, .6);
              z-index: -1;
              transition: .25s;
            }

            &:hover {
              &::after {
                background: rgba($blue-dark, .8);
              }
            }

            span {
              color: $white;
              text-transform: uppercase;

              @include breakpoint(medium) {
                font-size: 27px;
              }
            }
          }
        }

        .benefit-details {
          background: $blue-light;
          padding: 3rem 1rem;
          position: relative;
          margin-bottom: 2rem;
          color: $white;

          @include breakpoint(medium) {
            columns: 2;
          }

          .button {
            display: inline-block;
            margin-top: 1rem;
          }

          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 0;
            width: calc((100vw - #{$global-width}) / 2);
            background: $blue-light;
            height: 100%
          }

          &::before {
            left: calc((100vw - #{$global-width}) / -2);
          }

          &::after {
            right: calc((100vw - #{$global-width}) / -2);
          }
        }
      }
    }

    section.testimonials {
      padding: 3rem 0;
      background: $blue-light;

      h3 {
        text-transform: uppercase;
        @extend h1;
        color: $white;
        margin-bottom: 3rem;
      }

      .testimonial {
        margin-bottom: 2rem;

        .image {
          display: block;
          text-align: center;
          margin-bottom: 1rem;
        }

        .info {
          color: $white;

          h6 {
            font-weight: bold;
            font-size: 18px;
          }

          @include breakpoint(medium) {
            margin-left: 1rem;
          }
        }
      }
    }

    section.partnerships {
      padding: 5rem 0;
      background: $blue-dark;

      h3 {
        @extend h1;
        color: $white;
        text-transform: uppercase;
        margin-bottom: 3rem;
      }

      .slider-container {
        position: relative;

        .slick-slide {
          text-align: center;

          img {
            margin: 0 auto;
          }
        }

        .image-slider-arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;

          &.arrow-prev {
            left: -28px;
          }

          &.arrow-next {
            right: -28px;
          }
        }
      }
    }

    section.contact {
      background: $blue-light;
      padding: 5rem 0;

      h3 {
        @extend h1;
        text-transform: uppercase;
        color: $white;
      }
    }
  }
}
