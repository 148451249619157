.career-posting {
  background: $blue-light;
  color: $white;
  font-family: $secondary-font;
  margin-bottom: rem-calc(50);
  .details {
    padding: rem-calc(25);
    h3 {
      font-family: $secondary-font;
      text-transform: uppercase;
    }
    h4 {
      font-family: $secondary-font;
      padding-bottom: rem-calc(20);
    }
    p {
      margin: 0;
    }
  }

  .details-right {
    padding: rem-calc(25);
    .header {
      p {
        margin: 0;
      }
    }
    a {
      color: $white;
      text-transform: uppercase;
      transition: .25s ease-in-out;
      &:hover {
        opacity: .7;
      }
    }
  }

  .button {
    color: $black !important;
    @include breakpoint(medium down) {
      margin: rem-calc(20 0);
    }
    @include breakpoint(topbar down) {
      padding: rem-calc(20 40);
    }
  }
}

.e-verify {
  padding-bottom: rem-calc(100);
  .container {
    background: $blue-dark;
    color: $white;
    padding: rem-calc(30);
    font-family: $secondary-font;
    h4 {
      font-size: rem-calc(16);
      text-transform: uppercase;
      font-weight: bold;
      font-family: $secondary-font;
    }

    .address {
      padding-bottom: rem-calc(20);
    }

    .tel {
      p {
        margin: 0;
      }
      a {
        color: $white;
        transition: .25s ease-in-out;
        &:hover {
          opacity: .7;
        }
      }
      @include breakpoint(small only) {
        padding-bottom: rem-calc(30);
      }
    }

    img {
      @include breakpoint(small only) {
        padding-bottom: rem-calc(20);
      }
    }

    .e-verify-statement {
      p {
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: rem-calc(1.5);
        margin: 0;
      }
    }

    .dhs {
      padding-bottom: rem-calc(30);
      @include breakpoint(small only) {
        padding-bottom: 0;
      }
    }

    .eng-links, .spanish-links {
      p {
        margin: 0;
        a {
          color: $white;
          transition: .25s ease-in-out;
          &:hover {
            opacity: .7;
          }
        }
      }
    }
    .spanish-links {
      text-align: left;
      @include breakpoint(tablet) {
        text-align: right;
      }
    }
  }
}
