section.texture {
  padding: rem-calc(50 0);
  h2 {
  font-size: rem-calc(44);
  text-transform: uppercase;
  padding-bottom: rem-calc(50);
  letter-spacing: rem-calc(2);
  }
  .content {
    ul {
      list-style-position: inside;
      li {
        line-height: 1.9;
        letter-spacing: rem-calc(1.5);
      }
    }
  }
}
