.site-footer {
  padding: rem-calc(40 0);

  .info-col {
    padding-left: rem-calc(70);
    @include breakpoint(footer down) {
      padding-left: rem-calc(0);
    }
  }

  .icon-col {
    padding-right: rem-calc(70);
    @include breakpoint(footer down) {
      padding-right: rem-calc(0);
    }
  }

  h4 {
    margin: 0;
    font: {
      size: rem-calc(16);
      family: $secondary-font;
    }
    a {
      color: $black;
      transition: .25s ease-in-out;
      &:hover {
        opacity: .7;
      }
    }
  }

  .icons {
    img {
      width: rem-calc(50);
      transition: .25s ease-in-out;
      &:hover {
        opacity: .7;
      }
    }
  }
}

[data-footer-mobile] {

  .icon-col {
    padding-bottom: rem-calc(40);
  }

  .info-col {
    @include breakpoint(431px down) {
      text-align: center;
    }
    @include breakpoint(432px) {
      text-align: auto;
    }
  }

  h4 {
    line-height: 1.8;
    color: $white;
    a {
      color: $white;
    }
  }
}
