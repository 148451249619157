section.contact-us {
  font-family: $secondary-font;
  padding: rem-calc(50 0);
  .contact-office-container {
    padding: rem-calc(50 0);

    .image {
      @include breakpoint(medium down) {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.address-info {
  h4 {
    font-size: rem-calc(40);
    text-transform: uppercase;
  }
  p {
    font-size: rem-calc(16);
    margin: 0;
    @include breakpoint(medium down) {
      display: flex;
      justify-content: center;
    }
  }
  a {
    color: $black;
  }
  .spacing {
    padding-bottom: rem-calc(25);
    @include breakpoint(medium down) {
      text-align: center;
    }
  }
}

.video-info {
  h4 {
    font-size: rem-calc(40);
    text-transform: uppercase;
  }
  p {
    font-size: rem-calc(16);
    margin: 0;
    @include breakpoint(medium down) {
      display: flex;
      justify-content: center;
    }
  }
}

.map {
  iframe {
    width: 100%;
  }
}
