
section.single-projects {
  padding: rem-calc(50 0);
  .header {
    padding-bottom: rem-calc(50);
    h1 {
      letter-spacing: rem-calc(2);
      margin: 0;
    }
    h3 {
      font: {
        family: $secondary-font;
        weight: 100;
      }
    }
  }

  // Left col
  .left-col {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background: $blue-light;
    padding: rem-calc(50);
    color: $white;
    font-family: $secondary-font;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: block;
    }
    h3 {
      font-weight: 100;
    }
    a  {
      margin-top: rem-calc(50);
    }
    p {
      align-self: flex-start;
    }

    .center {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .filter {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: rem-calc(50);

      .prev-post,.next-post {
        a {
          color: $white;
          transition: .25s ease-in-out;
          &:hover {
            opacity: .7;
          }
        }
      }
      .prev-post:before {
        content: '<< ';
      }
      .next-post:after {
        content: ' >>';
      }
    }
    .directory {
      margin: 0;
      color: $white;
      transition: .25s ease-in-out;
      &:hover {
        opacity: .7;
      }
    }
  }

  // Right Col
  .right-col {
    h1,h2,h3,h4,h5,h6,p,li {
      font-family: $secondary-font;
      font-weight: 100;
    }
    img {
      width: 100%;
    }
  }
}
