section.key-projects {

  .project-blocks {
    background: $blue-light;
    margin-bottom: rem-calc(20);
    img {
      height: rem-calc(300);
      padding: rem-calc(20);
    }

    .project-blocks-info {
      padding: rem-calc(50 0);
      h3,h4,a {
        color: $white;
      }
      h3 {
        margin: 0;
        font: {
          size: rem-calc(32);
        }
      }
      h4 {
        padding-bottom: rem-calc(50);
        font: {
          size: rem-calc(24);
          family: $secondary-font;
        }
      }
      a {
      }
    }
  }
}
