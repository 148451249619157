.hero-404 {
  position: relative;
	z-index: 1;

  //Hero Video\
  .flex-video {
    position: relative;
    margin: 0;
    z-index: 2;
    .heading {
      width: 100%;
      color: $white;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      h1,h3 {
        text-transform: uppercase;
        letter-spacing: rem-calc(2);
      }
      h3 {
        padding-bottom: rem-calc(50);
        @include breakpoint(small only) {
          padding-bottom: 0;
          font-size: rem-calc(15);
        }
      }
      p {
        @include breakpoint(small only) {
          font-size: rem-calc(10);
        }
      }
    }
  }

  // Hero Image
  .hero-image {
    display: flex;
    align-items: center;
    background-position: center;
    @include breakpoint(navBreak) {
      padding-top: rem-calc(115);
    }

    // Hero Info Block
    .content {
      padding: rem-calc(150 0);
      position: relative;
      z-index: 10;
      .hero-cta {
        padding-bottom: rem-calc(50);
        a {
          padding: rem-calc(20 0);
          width: rem-calc(200);
          margin: rem-calc(10 10);
        }
      }
      h1,h3,p {
        color: $white;
        font-family: $secondary-font;
      }
      h1,h3 {
        letter-spacing: rem-calc(2);
      }
      a {
        margin: 0;
        font-size: rem-calc(24);
        font-family: $secondary-font;
        color: $white;
        transition: .25s;
        &:hover {
          opacity: .7;
        }
      }

      // Hero Nav Links
      .hero-menu {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: rem-calc(25);
        list-style-type: none;
        .menu-item {
          height: rem-calc(30);
          transition: .25s ease-in-out;
          &:hover{
            opacity: .7;
          }
          &:last-child {
            a {
              border: none;
            }
          }
          a {
            padding-right: 10px;
            padding-left: 10px;
            border-right: 1px $gold solid;
            line-height: rem-calc(15) !important;
            letter-spacing: rem-calc(2);
            text-transform: uppercase;
            color: $gold;
            font: {
              weight: 700;
            }
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  // Overlay
  .overlay,.video-overlay {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      top: 0;
      margin-top: auto;
      margin-bottom: auto;
      width: 100%;
      height: 75%;
      z-index: 1;
      background-color: $overlay;
      color: #FFF;
      @include breakpoint(navBreak) {
        content: '';
        position: absolute;
        top: 0;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        width: 65%;
        height: 100%;
        z-index: 1;
        background-color: $overlay;
        color: #FFF;
      }
    }
  }
  .video-overlay {
    &:before {
      height: 100%;
      @include breakpoint(navBreak) {
        width: 100%;
      }
    }
  }
}
