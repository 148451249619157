section.icons {
  background: $blue-dark;
  padding: rem-calc(40 0);
  @include breakpoint(icons) {
    height: rem-calc(200);
  }
  @include breakpoint(small only) {
    display: none;
  }

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: rem-calc(25);
    padding-left: rem-calc(25);
    &:hover img{
      width: rem-calc(90);
    }
    img {
      width: rem-calc(82);
      padding-bottom: rem-calc(15);
      transition: .25s ease-in-out;
    }
    h4  {
      margin-bottom: 0;
      color: $gold;
      font: {
        size: rem-calc(12);
        weight: bold;
      }
      text-transform: uppercase;
    }
  }
}
