section.certifications {
  background: $blue-dark;
  padding: rem-calc(75 0);

  h2 {
    padding-bottom: rem-calc(40);
    font-size: rem-calc(44);
    text-transform: uppercase;
    color: $white;
    letter-spacing: rem-calc(2);
    @include breakpoint(small only) {
      font-size: rem-calc(24);
    }
  }


  .cert-slider {
    .cert-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: rem-calc(150);
      }
    }

    // Slick Arrows
    @include slickArrows;
  }
}
