section.sub-service-blocks {
  padding: rem-calc(50 0);
  .flex {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .description {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: rem-calc(50);
      margin: rem-calc(0 10 20 10);
      width: rem-calc(650);
    }

    //Blocks
    .block {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: rem-calc(0 10 20 10);
      height: rem-calc(325);
      width: rem-calc(325);
      transition: .25s ease-in-out;
      @include breakpoint(720px down) {
        height: rem-calc(300);
        width: rem-calc(300);
      }
      @include breakpoint(670px down) {
        height: rem-calc(200);
        width: rem-calc(200);
      }
      @include breakpoint(460px down) {
        height: rem-calc(170);
        width: rem-calc(170);
      }
      @include breakpoint(400px down) {
        height: rem-calc(120);
        width: rem-calc(120);
      }
      // &:hover{
      //   cursor: pointer;
      //   opacity: .7;
      // }
      h3 {
        color: $white;
        font-size: rem-calc(32);
        text-transform: uppercase;
        position: relative;
        z-index: 2;
        @include breakpoint(670px down) {
        font-size: rem-calc(28);
        }
        @include breakpoint(460px down) {
          font-size: rem-calc(22);
        }
        @include breakpoint(400px down) {
          font-size: rem-calc(16);
        }
      }
    }
    .block-hidden {
      margin: rem-calc(0 10 20 10);
      height: rem-calc(325);
      width: rem-calc(325);
      @include breakpoint(720px down) {
        height: rem-calc(300);
        width: rem-calc(300);
      }
      @include breakpoint(670px down) {
        height: rem-calc(200);
        width: rem-calc(200);
      }
      @include breakpoint(460px down) {
        height: rem-calc(170);
        width: rem-calc(170);
      }
      @include breakpoint(400px down) {
        height: rem-calc(120);
        width: rem-calc(120);
      }
    }

    //Toggled Content
    .block-content {
      background: $blue-light;
      display: none;
      width: 100vw;
      margin-left: calc(-50vw + 50%);
      margin-right: calc(-50vw + 50%);
      margin-bottom: rem-calc(20);
      .flex {
        display: flex;
        justify-content: center;

        .info-container {
          display: flex;
          justify-content: flex-start;
          width: rem-calc(750);
          padding: rem-calc(50 20 50 20);
          color: $white;
          position: relative;
          @include breakpoint(small down) {
            flex-direction: column;
          }

          .left {
            padding-right: rem-calc(20);
          }

          .right {
            padding-left: rem-calc(20);
          }

          .left, .right {
            width: 50%;
            text-align: left;
            font-family: $secondary-font;
            @include breakpoint(small down) {
              width: 100%;
            }
            h3,h4,h5,h6,p,li {
              font-family: $secondary-font;
            }
            ul {
              list-style-position: inside;
              text-align: left;
            }
            h4 {
              font-size: rem-calc(33);
            }
            .btn {
              display: flex;
              justify-content: center;
              padding-top: rem-calc(25);
            }
          }
          // .left {
          //   margin-right: rem-calc(15);
          //   @include breakpoint(small down) {
          //     padding-left: rem-calc(15);
          //   }
          // }
          // .right {
          //   margin-left: rem-calc(15);
          //   @include breakpoint(small down) {
          //     margin: 0;
          //   }
          // }
        }
      }
    }

    // Overlay
    .overlay {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        margin-top: auto;
        margin-bottom: auto;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: $blue-overlay;
        color: #FFF;
        transition: .25s ease-in-out;
      }
      &:hover::before{
        cursor: pointer;
        background-color: transparent;
      }
    }
  }
}
