@mixin btn {
  padding: rem-calc(20 65);
  background: $gold;
  color: $black;
  font-size: rem-calc(15);
  text-transform: uppercase;
  letter-spacing: rem-calc(1);
  border-radius: 7px;
  transition: .25s ease-in-out;
  &:hover {
    background: $gold;
    color: $black;
    font-weight: 700;
  }
  &:active {
    background: $gold;
  }
  &:focus {
    background: $gold;
    color: $black;
  }
}

//Slick Arrows
@mixin slickArrows {
  .slick-prev, .slick-next {
    top: -10px;
  }

  .slick-prev {
    left: -85px;
    @include breakpoint(small only) {
      left: -75px;
    }
    &:before {
      font-family: fontAwesome;
      content: "\f0d9";
    }
  }

  .slick-next {
    right: -75px;
    @include breakpoint(small only) {
      right: -50px;
    }
    &:before {
      font-family: fontAwesome;
      content: "\f0da";
    }
  }

  .slick-prev:before, .slick-next:before {
    font-size: rem-calc(100);
    color: white;
    opacity: 1;
  }
}
