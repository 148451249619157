section.related-projects {
  background: $blue-dark;
  padding: rem-calc(50 0);
  color: $white;
  h3 {
    padding-bottom: rem-calc(50);
    font-size: rem-calc(36);
    text-transform: uppercase;
    letter-spacing: rem-calc(2);
  }

  .featured-slide {
    padding: rem-calc(0 30);
    img {
      @include breakpoint(medium down) {
        padding-bottom: rem-calc(20);
      }
    }
    h4 {
      padding-bottom: rem-calc(10);
      font-weight: 700;
      font-size: rem-calc(15);
      letter-spacing: rem-calc(1);
    }
    p {
      font-size: rem-calc(14);
    }
    a {
      color: $white;
      transition: .25s ease-in-out;
      &:hover {
        opacity: .7;
      }
    }
  }

  // Slick Arrows
  @include slickArrows;
}

section.related-projects .slick-prev, section.related-projects .slick-next {
  top: 20px;
}
