section.contact-us-form {
  background: $blue-light;
  padding: rem-calc(50 0);

  .contact-header {
    h2 {
      font-size: rem-calc(44);
      padding-bottom: rem-calc(40);
      color: $white;
      text-transform: uppercase;
      letter-spacing: rem-calc(2);
    }
  }

  // Contact Form
  .infusion-form-single {
    textarea,input {
      border-radius: 7px;
      &::-webkit-input-placeholder {
         color: $black;
       }
       &:-moz-placeholder {
         color: $black;
         opacity: 1;
       }
       &::-moz-placeholder {
         color: $black;
         opacity: 1;
       }
       &:-ms-input-placeholder {
         color: $black;
       }
    }
    textarea {
      height: rem-calc(170);
    }
  }
}
