section.project-filter-container {
  padding: rem-calc(75 0);
  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    li {
      @include breakpoint(small down) {
        padding-bottom: rem-calc(10);
      }
      &:last-child {
        a {
          border: none;
        }
      }
      a {
        padding: rem-calc(0 10);
        color: $black;
        border-right: 1px $black solid;
        line-height: rem-calc(15) !important;
        text-transform: uppercase;
        font: {
          weight: 300;
        }
        &:hover {
          font-weight: 700;
        }
      }
    }
  }
}
