section.image-library {
  .header {
    padding: rem-calc(90 0);
    h3 {
      text-transform: uppercase;
    }
  }


  .gallery-row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: rem-calc(90);
    .image {
      height: 170px;
      // height: 0;
      padding-bottom: 15%;
      width: 15%;
      margin-right: rem-calc(7.5);
      margin-left: rem-calc(7.5);
      margin-bottom: rem-calc(15);
      @include breakpoint(small) {
        width: calc(50% - 15px);
        padding-bottom: calc(50% - 15px);
      }
      @include breakpoint(keiki) {
        width: calc(33% - 15px);
        padding-bottom: calc(33% - 15px);
      }
      @include breakpoint(tablet) {
        width: calc(25% - 15px);
        padding-bottom: calc(25% - 15px);
      }
      @include breakpoint(large) {
        width: calc(20% - 15px);
        padding-bottom: calc(20% - 15px);
      }
      @include breakpoint(xlarge) {
        width: calc(14.28% - 15px);
        padding-bottom: calc(14.28% - 15px);
      }
    }
    .overlay {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        margin-top: auto;
        margin-bottom: auto;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: $blue-overlay;
        color: #FFF;
        transition: .25s ease-in-out;
      }
      &:hover::before{
        cursor: pointer;
        background-color: transparent;
      }
    }
  }
}

.library-reveal {
  background: $grey-warm;
  padding: rem-calc(50);
  .library-slide {
    img {
      max-height: 600px;
    }
    p {
      text-align: center;
      padding-top: rem-calc(50);
    }
  }
  // Slick Arrows
  .slick-prev, .slick-next {
    // top: -10px;
  }

  .slick-prev {
    left: -40px;
    &:before {
      font-family: fontAwesome;
      content: "\f0d9";
    }
  }

  .slick-next {
    right: -40px;
    &:before {
      font-family: fontAwesome;
      content: "\f0da";
    }
  }

  .slick-prev:before, .slick-next:before {
    font-size: rem-calc(50);
    color: $blue-light;
    opacity: 1;
  }
}
